<template lang="pug">
    .operations-debit
        .title(v-if="!hideWithdrawalMethods") {{ $t('profile.payments.paymentMethodLabel') }}
        .title(v-if="isCardsActive") {{ $t('profile.payments.withdrawalCardsLabel') }}

        .debit-methods(v-if="!hideWithdrawalMethods")
            .cards(v-if="cardsFlag !== '0'" @click="handleMethod('cards')"
                  :class="{ 'active': isCardsActive, 'disabled': cardDisabled }")
              .up-head
                span {{ $t('profile.payments.withdrawalCardLabel') }}

              .pmethods
                .card-icons(v-for="(method, idx) in modifiedCards" :key="method.id"
                        :class="{ 'disabled': method.disable, 'active': method.showMode }")
                    img.pmethod-image(:src="method.image" alt="No image")
            .crypto(v-if="cryptoFlag !== '0'" @click="handleMethod('crypto')"
                  :class="{ 'active': isCryptoActive, 'disabled': cryptoDisabled }")
              .up-head
                span {{ $t('profile.payments.withdrawalCryptoLabel') }}

              .pmethods
                .card-icons(v-for="(method, idx) in d_crypto" :key="method.id"
                        :class="{ 'disabled': method.disable, 'active': method.showMode }")
                    img.pmethod-image(:src="method.image" alt="No image")

            .companies(v-if="companiesFlag !== '0'" @click="handleMethod('companies')"
                      :class="{ 'active': isCompanyActive, 'disabled': true }")
              .up-head
                span {{ $t('profile.payments.withdrawalCompanyLabel') }}

              .pmethods.ew
                .ew(:class="{ 'disabled': method.disable, 'active': method.showMode }" v-for="(method, idx) in d_e_wallets" :key="method.id")
                  img.ew-image(:src="method.image" alt="No image")

        .debit-payment(v-if="hideWithdrawalMethods")
          ValidationObserver(ref="withdrawalPayment" v-slot="{ passes }" tag="form")
            form.payment(:class="{ 'active': isGenerateActive }" @submit.prevent="passes(handleWithdrawal)")
              .body
                  .body-top
                    .ccard-first-wrapper(v-if="isCardsActive")
                      .ccard-img-wrapper
                        img.ccard-img(:src="require('@/assets/images/personal/payments/mini_card_icon.svg')" alt="cards")
                      TextInput(name="card" :renderedTextLabel="$t('profile.payments.cardNumberLabel')"
                                rules="required|luhn|length:19" @textInputEmit="listenTextInput"
                                :validDiv="false" :invalidDiv="false"
                                :hasVMask="true" :maskTemplate="'#### #### #### ####'" :floatingLabel="true"
                                :isOperations="true" :clearFields="clearFields")
                    .ccard-second-wrapper(v-if="isCardsActive")
                      TextInput(name="cardholder" :renderedTextLabel="$t('profile.payments.cardHolderLabel')"
                                rules="required|cardholderLength" @textInputEmit="listenTextInput"
                                :validDiv="false" :invalidDiv="false" :floatingLabel="true"  :hasVMask="true"
                                :isOperations="true" :clearFields="clearFields")

                    //  TextInput.exp-wrapper(name="exp" :renderedTextLabel="$t('profile.payments.expirationLabel')"
                    //            :key="updateKey+2" :floatingLabel="true"
                    //            rules="required|length:7"
                    //            @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                    //            :isOperations="true" :hasVMask="true" :maskTemplate="'## / ##'"
                    //            :clearFields="clearFields")
                    //
                    //  TextInput(name="cvc" :renderedTextLabel="$t('profile.payments.cvcLabel')"
                    //            rules="required|length:3" :key="updateKey+3" :floatingLabel="true"
                    //            @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                    //            :isOperations="true"
                    //            :hasVMask="true" :maskTemplate="'###'"
                    //            :isPssswordFont="true" :clearFields="clearFields")

                    .input-wrapper(v-if="isCryptoActive")
                        Dropdown(:itemArray="cryptoCurrencyArray"
                          @emitDDItem="currencyListener($event)"
                          dropdownType="datepicker")
                          template(v-slot:currency)
                            span.dd-floating-label(ref="currencyFlSpan" ) {{ $t('profile.settings.settingsCurrencyLabel') }}
                            .currency-picker-content
                              .currency-picker__text(:class="{'filled': selectedCurrency != $t('profile.settings.settingsCurrencyLabel')}") {{ selectedCurrency }}
                              .currency-picker__chevron
                    .input-wrapper(:class="{ 'cc': isCardsActive }")
                        TextInput(name="summ" :renderedTextLabel="amountPlaceholder"
                                :rules="getAmountRules"
                                :floatingLabel="true"
                                :renderedTextEwp="getWrongAmountMessage"
                                @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                                :isOperations="true" refText="summInput" step="0.01" :clearFields="clearFields")
                    .willbe-wrapper(v-if="withdrawalAmount") {{ withdrawalAmount + getCurrency + ' ' + ($t('profile.payments.willBeDebitLabel')) }}
                    .insufficient-funds-wrapper(v-if="withdrawalAmount > getBalance") {{ $t('profile.payments.insufficientFundsLabel') }}
                    .input-wrapper(v-if="isCryptoActive")
                        TextInput(name="cryptoAddress" :renderedTextLabel="addressPlaceholder" rules="required"
                                :floatingLabel="true" :validDiv="false" :invalidDiv="false"
                                @textInputEmit="listenTextInput"
                                :isOperations="true" refText="addressInput" :clearFields="clearFields")
                    .wrong-address-wrapper(v-if="!isValidAddress") {{ $t('profile.payments.wrongAddressLabel') }}

                  .body-bottom
                    p.fees(v-if="getFee > 0") {{ $t('profile.payments.feePaymentLabel') + ' ' + getFee + getCurrency }}

                    p.agree {{ $t('profile.payments.agree1Label') }}
                    p.agree-link(@click="callPaymentPolicy()") {{ $t('footer.footerPaymentPolicyLabel') }}
                     p.forb(v-if="!isCryptoActive") {{ $t('profile.payments.agree3Label') }}

                    .deposit-btn-wrapper
                        button.deposit-btn(:class="{ 'disabled': isButtonDisable, 'clicked': isWithdrawalClicked }"
                                    type="submit") {{ $t('profile.payments.withdrawalButton') }}
</template>

<script>

const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');
const Dropdown = () => import('@/components/serviceComponents/dropdown/DropdownSW');

import Withdrawal from '../Withdrawal.vue';


export default {
  extends: Withdrawal,
  components: {
    TextInput,
    Dropdown
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/personal/withdrawal_sw.scss";
</style>