<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { extend } from 'vee-validate';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';
import addressValidator from 'multicoin-address-validator';



function luhnAlgorithm(value) {
    value = value.replace(/\D/g, '');

    var nCheck = 0;
    var bEven = false;

    for (var n = value.length - 1; n >= 0; n--) {
        var nDigit = parseInt(value.charAt(n), 10);

        if (bEven && (nDigit *= 2) > 9) {
            nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) == 0;
}

extend('cardholderLength', value => {
  return value.length >= 3 && value.length <= 32;
});
extend('minWithdrawal', value => {
    return value >= 5;
});
extend('maxWithdrawal', value => {
    return value <= 20000;
});
extend('cryptoMinWithdrawal', value => {
  return !Number.isNaN(value) && value >= 0.0001;
});
extend('cryptoMaxWithdrawal', value => {
  return value <= 20000;
});
extend('luhn', value => {
  return luhnAlgorithm(value);
});

export default {
  name: 'Withdrawal',
  data: () => ({
    currencyFormat: convertCurrencyToSymbol,
    moneyFormat: displayCoins,
    currentMethod: {},
    withdrawalValue: null, //сумма в валюте selectedCurrency, которую выбрал пользователь
    withdrawalAmount: null, //сумма для списания в валюте счета пользователя
    fee: null,
    isWithdrawalClicked: false,
    cardNumber: {
      number: '',
      expiration: '',
      cvc: '',
      holder: ''
    },
    cryptoAddress: null,
    currentBlock: '',
    isCompanyActive: false,
    withdrawalWallet: null,
    isCardsActive: false,
    isCryptoActive: false,
    isGenerateActive: false,
    selectedCurrency: '',
    d_cards: [
      {
        disabled: true,
        inactive: false,
        id: 'visa',
        image: require('@/assets/images/personal/payments/visa.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle:'visa card'
      },
      {
        disabled: true,
        inactive: false,
        id: 'master',
        image: require('@/assets/images/personal/payments/master.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle:'master card'
      },
      {
        disabled: true,
        inactive: false,
        id: 'maestro',
        image: require('@/assets/images/personal/payments/maestro.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle: 'maestro card'
      },
      {
        disabled: true,
        inactive: false,
        id: 'cirrus',
        image: require('@/assets/images/personal/payments/cirrus.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle: 'cirrus card'
      },
      {
        disabled: true,
        inactive: false,
        id: 'ae',
        image: require('@/assets/images/personal/payments/american_express.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle: 'ae card'
      },
      {
        disabled: true,
        inactive: false,
        id: 'mir',
        image: require('@/assets/images/personal/payments/mir.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle: 'mir card'
      },
    ],
    d_e_wallets: [
      {
        disabled: true,
        inactive: false,
        id: 'webmoney',
        image: require('@/assets/images/personal/payments/webmoney.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle:'webmoney'
      },
      {
        disabled: true,
        inactive: false,
        id: 'yadengi',
        image: require('@/assets/images/personal/payments/yandex.svg'),
        showMode: false,
        title: 'profile.payments.withdrawalLabel',
        staticTitle:'yandex dengi'
      },
    ],
    d_crypto: [
      {
        id: 'btc',
        image: require('@/assets/images/personal/payments/btc.svg'),
      },
      {
        id: 'etc',
        image: require('@/assets/images/personal/payments/etc.svg'),
      },
      {
        id: 'eth',
        image: require('@/assets/images/personal/payments/eth.svg'),
      },
      {
        id: 'usdc',
        image: require('@/assets/images/personal/payments/usdc.svg'),
      },
      {
        id: 'bnb',
        image: require('@/assets/images/personal/payments/bnb.svg'),
      },
      {
        id: 'usdt',
        image: require('@/assets/images/personal/payments/usdt.svg'),
      },
    ],
    modifiedCards: [],
    clearFields: false,
    hideWithdrawalMethods: false,
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
      openSection: 'personal/getOpenSection',
      userBalance: 'user/getBalance',
      paymentMethods: 'pay/getPaymentMethods',
      rates: 'pay/getRates',
    }),

    cardDisabled() {
      return !this.paymentMethods || this.paymentMethods.filter(e => e.payment_system_code === 'card').length === 0;
    },

    cryptoDisabled() {
      return !this.paymentMethods || this.paymentMethods.filter(e => e.payment_system_code === 'crypto').length === 0;
    },

    cryptoCurrencyArray() {
      return this.paymentMethods.filter(e => e.payment_system_code === 'crypto').map(e => e.currency_code);
    },

    getPaymentMethod() {
      if (this.paymentMethods) {
        let methods = this.paymentMethods.filter(e => e.payment_system_code ===
            (this.isCardsActive ? 'card' : this.isCryptoActive ? 'crypto' : ''));
        if (methods.length > 0) {
          return methods[0];
        }
      }
      return null;
    },

    getFee() {
      if (this.getPaymentMethod && this.withdrawalValue > 0) {
        if (this.getPaymentMethod.withdrawal_fee_percent <= 0) {
          return 0;
        } else {
          let fee = (Math.trunc(this.withdrawalValue * this.getPaymentMethod.withdrawal_fee_percent / 100
              * Math.pow(10, this.getPaymentMethod.minor_unit))
              / Math.pow(10, this.getPaymentMethod.minor_unit)).toFixed(this.getPaymentMethod.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');
          if (fee < parseFloat(this.moneyFormat( this.getPaymentMethod.withdrawal_fee_min, this.getPaymentMethod, false))) {
            fee = this.moneyFormat( this.getPaymentMethod.withdrawal_fee_min, this.getPaymentMethod, false);
          }
          return fee;
        }
      }
      return 0;
    },

    companiesFlag() {
      return '1';
    },
    cardsFlag() {
      return '2';
    },
    cryptoFlag() {
      return '2';
    },
    amountPlaceholder() {
      return `${this.$t('profile.payments.amountLabel')} ` + this.selectedCurrency; //this.currencyFormat(this.userBalance.currency);
    },
    addressPlaceholder() {
      return this.$t('profile.payments.addressPlaceholder');
    },
    getMinAmount(){
      let moneyFormat = this.moneyFormat(this.getPaymentMethod.withdrawal_min, this.getPaymentMethod, false);
      return moneyFormat ? moneyFormat : 0;
    },
    getMaxAmount(){
      return this.moneyFormat(this.getPaymentMethod.withdrawal_max, this.getPaymentMethod, false);
    },
    getCurrency(){
      return this.currencyFormat(this.userBalance.currency);
    },
    getBalance() {
      return parseFloat(this.moneyFormat(this.userBalance.value, this.userBalance, false));
    },
    getAmountRules() {
      if (this.getMaxAmount>0) {
        return `required|cryptoMinWithdrawal|between:${this.getMinAmount},${this.getMaxAmount}`;
      } else {
        return 'required|cryptoMinWithdrawal';
      }
    },
    isButtonDisable(){
      return !this.isCryptoActive || (
      !this.withdrawalAmount || this.withdrawalAmount > this.getBalance || !this.isValidAddress
      || !this.selectedCurrency || this.selectedCurrency===''
      );
    },
    getWrongAmountMessage(){
      if (this.getMaxAmount>0) {
        return (this.$t('profile.payments.wrongAmountMessage'))
            .replaceAll('{min}', this.getMinAmount + ' ' + this.getCurrency)
            .replaceAll('{max}', this.getMaxAmount + ' ' + this.getCurrency);
      } else {
        return (this.$t('profile.payments.requiredAmountMessage'));
      }
    },
    isValidAddress() {
      if (this.cryptoAddress) {
        if (this.selectedCurrency === 'USDTRX') {
          return /^T[A-Za-z1-9]{33}$/.test(this.cryptoAddress);
        } else if (this.selectedCurrency === 'XMR') {
          return addressValidator.validate(this.cryptoAddress, this.selectedCurrency);
        }
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      doPay: 'pay/doPay',
      fetchRate: 'pay/fetchRate',
      fetchAvailableMethods: 'pay/fetchAvailableMethods'
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION'
    }),
    async currencyListener(data) {
      if (data) {
            this.selectedCurrency = data;
            await this.fetchRate(this.selectedCurrency);
            this.calcAmount();
        }
    },
    async calcAmount() {
      if (this.withdrawalValue && this.rates.hasOwnProperty(this.selectedCurrency)) {
        let amount = this.withdrawalValue * this.rates[this.selectedCurrency];
        amount = amount.toFixed(this.userBalance.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');
        this.withdrawalAmount = amount;
      }
    },
    async callPaymentPolicy() {
      this.SET_OPEN_PAGE('paymentPolicy');
      this.$router.push('/payment-policy');
      window.scrollTo(0, 0);
    },
    handleMethod(block_name) {
      this.currentBlock = block_name;
      this.isClickEnabled = true;
      this.withdrawalWallet = null;
      this.selectedCurrency = '';
      this.withdrawalAmount = null;
      this.cryptoAddress = null;

      if (block_name === 'cards') {
        this.isCompanyActive = false;
        this.isCardsActive = true;
        this.isCryptoActive = false;
        this.hideWithdrawalMethods = true;
      }
      else if (block_name === 'companies') {
        this.isCompanyActive = true;
        this.isCardsActive = false;
        this.isCryptoActive = false;
        this.hideWithdrawalMethods = true;
      }
      else if (block_name === 'crypto') {
        this.isCompanyActive = false;
        this.isCardsActive = false;
        this.isCryptoActive = true;
        this.hideWithdrawalMethods = true;
      }
    },
    async handleWithdrawal() {
      this.isWithdrawalClicked = true;

      // if (this.withdrawalValue > this.userBalance.value) {
      //
      // }
      let source = null;
      let pan = null;
      let address = null;
      let cardholder = null;
      let currency = this.selectedCurrency;
      let amount = null;
      let withdrawAmount = null;
      let fee = this.getFee;
      if (this.isCardsActive) {
        source = 'card';
        pan = this.cardNumber.number.replace(/\s/g, '');
        cardholder = this.cardNumber.holder;
        amount = this.withdrawalValue;
      } else if (this.isCryptoActive) {
        source = 'crypto';
        address = this.cryptoAddress;
        amount = this.withdrawalValue;
        withdrawAmount = this.withdrawalAmount;
      }
      if (await this.doPay({type: 'withdrawal', source, pan, cardholder, address, amount, currency, withdrawAmount, fee})){
        this.SET_OPEN_SECTION('paymentsHistory');
      }
    },
    addInitialSettings(withdrawalItem) {
      if (withdrawalItem.id === 'cards') {
        if (this.cardsFlag === '0') {
          withdrawalItem.inactive = true;
          withdrawalItem.showMode = false;
        } else if (this.cardsFlag === '1') {
          withdrawalItem.inactive = false;
          withdrawalItem.disabled = true;
          withdrawalItem.showMode = false;
        } else if (this.cardsFlag === '2') {
          withdrawalItem.inactive = false;
          withdrawalItem.disabled = false;
          withdrawalItem.showMode = false;
        }
      }

      if (
        withdrawalItem.id === 'atlasmara' ||
        withdrawalItem.id === 'etumba' ||
        withdrawalItem.id === 'cavmont' ||
        withdrawalItem.id === 'bank_of_china' ||
        withdrawalItem.id === 'zoona' ||
        withdrawalItem.id === 'mfinance' ||
        withdrawalItem.id === 'investrust' ||
        withdrawalItem.id === 'kazang' ||
        withdrawalItem.id === 'indozambia' ||
        withdrawalItem.id === 'konse_konse' ||
        withdrawalItem.id === 'citibank' ||
        withdrawalItem.id === 'zamtel' ||
        withdrawalItem.id === 'airtel' ||
        withdrawalItem.id === 'mtn'
      ) {
        if (this.companiesFlag === '0') {
          withdrawalItem.inactive = true;
          withdrawalItem.showMode = false;
        } else if (this.companiesFlag === '1') {
          withdrawalItem.inactive = false;
          withdrawalItem.disabled = true;
          withdrawalItem.showMode = false;
        } else if (this.companiesFlag === '2') {
          withdrawalItem.inactive = false;
          withdrawalItem.disabled = false;
          withdrawalItem.showMode = false;
        }
      }
    },
    listenTextInput(data) {
      if (data) {
        switch(data.name) {
          case 'card': {
            this.cardNumber.number = data.value;
            return;
          }
          case 'summ': {
            this.withdrawalValue = data.value.trim();
            this.calcAmount();
            return;
          }
          case 'cryptoAddress': {
            this.cryptoAddress = data.value;
            return;
          }
          case 'cardholder': {
            this.cardNumber.holder = data.value;
            return;
          }
        }
      }
    }
  },
  watch: {
    withdrawalValue(newWithdrawalValue) {
      if (newWithdrawalValue < 0 || newWithdrawalValue === '-') {
        this.withdrawalValue = '';
      }

      if (newWithdrawalValue === '0') {
        this.withdrawalValue = '';
      }
    },
    rates() {
      this.calcAmount();
    },
    isWithdrawalClicked(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.isWithdrawalClicked = false;
        }, 300);
      }
    },
    async openSection(newSection, oldSection) {
      if (newSection !== 'withdrawal' && oldSection === 'withdrawal') {
        this.withdrawalValue = null;
        this.hideWithdrawalMethods = false;
        this.isCardsActive = false;
        this.$refs.withdrawalPayment.reset();
      }
      else if (newSection === 'withdrawal') {
        await this.fetchAvailableMethods();

        if (this.$metrika) {
          this.$metrika.reachGoal('withdrawalOpen');
        }
      }
    },
    selectedCurrency(newVal) {
      const node = this.$refs['currencyFlSpan'];

      if (newVal != null) {
        if (node != null) {
          node.classList.add('up');
        }
      }
      else {
        if (node != null) {
          node.classList.remove('up');
        }
      }
    },
  },
  async mounted() {
    await this.fetchAvailableMethods();
    this.d_cards.forEach(depCardItem => {
      this.addInitialSettings(depCardItem);
    });

    this.modifiedCards = this.d_cards.filter(method => method.inactive === false);

  },
  beforeDestroy() {
    this.hideWithdrawalMethods = false;
  },
};
</script>

<style lang="scss">
</style>